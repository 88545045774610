<template>
    <div class="py-4 lg:py-8 lg:pb-20 mt-4 md:mt-0">
        <div class="text-xl md:text-2xl text-gray-800 mb-6 flex flex-wrap items-center" v-show="this.video">
            <div class="flex items-center justify-center w-full md:w-auto md:justify-start" v-show="!editVideoMode">
                <h1>{{ this.videoTitle }}</h1>
                <button class="w-8 h-8 ml-2 flex items-center justify-center hover:bg-gray-200 rounded text-gray-500" @click="editVideoMode = true">
                    <Edit2Icon size="1x" />
                </button>
            </div>

            <div class="flex items-center flex-1" v-show="editVideoMode">
                <input class="p-2 w-full md:w-1/2" type="text" v-model="videoTitle" @keyup.enter="handleVideoTitleChange">
                <button class="w-8 h-8 ml-2 flex items-center justify-center hover:bg-gray-200 round" @click="handleVideoTitleChange">
                    <CheckIcon size="1x" />
                </button>
            </div>

            <div class="flex items-center justify-center text-xs mt-2 md:text-base md:ml-auto w-full md:w-auto">
                <button
                    ref="exportButton"
                    class="px-2 py-1 rounded text-base flex items-center justify-center hover:bg-primary hover:text-white mr-2"
                    :data-clipboard-text="exportUrl"
                >
                    <span class="mr-2">Share</span>
                    <ExternalLinkIcon size="1.2x" />
                </button>

                <button
                    class="px-2 py-1 rounded text-base flex items-center justify-center hover:bg-red-600 hover:text-white"
                    @click="handleDeleteVideo"
                >
                    <span class="mr-2">Delete</span>
                    <TrashIcon size="1.2x" />
                </button>
            </div>
        </div>
        <div ref="wrapper" class="w-full">
            <div ref="player"></div>
        </div>
        <div>
            <!-- player controls maybe -->
            <div class="py-4 flex flex-wrap items-center">
                <div class="flex items-center flex-wrap w-full mb-2">
                    <ClockIcon size="1.2x" />
                    <p class="ml-2">Tempo</p>
                </div>
                <div class="w-full lg:w-1/4 flex items-center px-2">
                    <div class="w-full">
                        <vue-slider
                            v-model="tempo"
                            :min="0.3"
                            :max="1"
                            :interval=".1"
                            :marks="true"
                            :contained="true"
                            tooltip="none"
                        />
                    </div>
                </div>
            </div>
            <div class="w-full relative mt-12 -mb-2">
                <div class="w-full py-1 px-2 flex items-center justify-end bg-gray-800 rounded-t">
                    <span class="text-sm text-white">{{ secondsPretty }}</span>
                </div>
                <div class="w-full h-10 bg-gray-200 shadow-inner border-l-black border-r-black border-opacity-20 relative">
                    <!-- empty for now as the label is absolutely positioned over the top, this is all smoke and mirrors baby -->
                    <div class="h-2 w-full bg-gray-300 absolute top-4" @click="handleTrackClick" @touchend="handleTrackClick"></div>
                    <div class="h-2 w-8 bg-primary absolute top-4 pointer-events-none" :style="{ width: `${playheadOffset}px`}"></div>
                </div>
            </div>
            <!-- The play head container -->
            <div class="w-full relative" ref="playheadContainer">
                <div class="w-full relative" v-if="playheadStepAmount">
                    <div
                        class="playhead-marker z-10 cursor-move"
                        :style="{ transform: `translateX(${playheadOffset}px)`}"
                        ref="playheadMarker"
                    >
                        <span
                            class="absolute -top-8 left-0 text-xs px-2 py-1 bg-red-600 text-white playhead-label rounded-sm"
                            ref="playheadMarkerLabel"
                        >{{ secondsPretty }}</span>
                    </div>
                    <div v-if="loops.length > 0">
                        <!-- Whole loop track compoennts -->
                        <Loop
                            v-for="loop in loops"
                            :key="loop.id"
                            :loop="loop"
                            :activeLoopId="activeLoopId"
                            @name-change="handleUpdateLoopName"
                            @activate="(id) => activeLoopId = id"
                            @delete="handleDeleteLoop"
                        >
                            <LoopBar
                                :start="loop.start"
                                :end="loop.end"
                                :stepAmount="playheadStepAmount"
                                :id="loop.id"
                                :countInMode="countInMode"
                                :countInAmount="countInAmount"
                                @loop:clicked="handleLoopClick"
                            />
                        </Loop>
                    </div>
                </div>
            </div>
            <div class="py-6">
                <button class="flex items-center justify-center px-4 py-4 text-primary rounded mx-auto border-primary border-dashed border-2 w-3/4 opacity-70 hover:opacity-100 transition-opacity duration-150" @click="handleAddLoop">
                    <PlusIcon size="1.2x"/>
                    <span class="ml-1">Add Loop</span>
                </button>
            </div>
        </div>

        <!-- touch controls -->
        <div class="fixed left-0 bottom-0 w-full px-4 py-4 bg-white border-t border-black border-opacity-10 z-20">
            <div class="container flex items-center mx-auto">
                <div
                    class="flex items-center transition-opacity duration-150 relative"
                    :class="{
                        'opacity-25': loops.length === 0
                    }"
                >
                    <div class="mr-2">
                        <button class="relative w-10 h-10 flex items-center justify-center rounded control-button shadow-sm" @click="setActiveLoopStart" title="Set Loop Start" :disabled="loops.length === 0">
                            <MarkStart class="relative" :w="19" :h="19" />
                        </button>
                        <span class="text-center text-sm text-gray-400 hidden mt-1 lg:block">(s)</span>
                    </div>
                    <div class="mr-6">
                        <button class="relative w-10 h-10 flex items-center justify-center rounded control-button shadow-sm" @click="setActiveLoopEnd" title="Set Loop End" :disabled="loops.length === 0">
                            <MarkEnd class="relative" :w="19" :h="19" />
                        </button>
                        <span class="text-center text-sm text-gray-400 hidden mt-1 lg:block">(e)</span>
                    </div>
                    <div class="mr-2">
                        <button
                            title="Loop Mode"
                            class="relative w-10 h-10 flex items-center justify-center rounded control-button shadow-sm"
                            :class="{ 'isActive': loopMode }"
                            @click="loopMode = !loopMode"
                            :disabled="loops.length === 0"
                        >
                            <RepeatIcon size="1.2x"/>
                        </button>
                        <span class="text-center text-sm text-gray-400 hidden mt-1 lg:block">(l)</span>
                    </div>
                    <div class="mr-6">
                        <button
                            title="Count In (temporaily adds 3 secs to start of loop)"
                            class="relative w-10 h-10 flex items-center justify-center rounded control-button shadow-sm"
                            :class="{ 'isActive': countInMode }"
                            @click="countInMode = !countInMode"
                            :disabled="loops.length === 0"
                        >
                            <CountIn class="relative" :w="19" :h="19" />
                        </button>
                        <span class="text-center text-sm text-gray-400 hidden mt-1 lg:block">(c)</span>
                    </div>
                    <div>
                        <button
                            title="Restart to start of active loop"
                            class="relative w-10 h-10 flex items-center justify-center rounded control-button shadow-sm"
                            @click="seekToActiveLoopStart"
                            :disabled="loops.length === 0"
                        >
                            <RefreshCcwIcon size="1.2x"/>
                        </button>
                        <span class="text-center text-sm text-gray-400 hidden mt-1 lg:block">(r)</span>
                    </div>
                </div>
                <button class="w-11 h-11 flex items-center justify-center bg-primary text-white rounded-full ml-auto" @click="handleTogglePlay">
                    <PlayIcon class="relative" size="1.2x" style="left: 2px;" v-show="player && player.getState() !== 'playing'"/>
                    <PauseIcon class="relative" size="1.2x" v-show="player && player.getState() === 'playing'"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import db from '../db';
    import YTPlayer from 'yt-player';
    import Loop from '../components/Loop';
    import LoopBar from '../components/LoopBar';
    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/default.css';
    import sf from 'seconds-formater';
    import MarkStart from '../components/icons/MarkStart';
    import MarkEnd from '../components/icons/MarkEnd';
    import CountIn from '../components/icons/CountIn';
    import ClipboardJS from 'clipboard';
    import { JSONCrush } from '@madono/jsoncrush';

    import {
        PlusIcon,
        RepeatIcon,
        TrashIcon,
        PlayIcon,
        PauseIcon,
        RefreshCcwIcon,
        ClockIcon,
        Edit2Icon,
        CheckIcon,
        ExternalLinkIcon
    } from 'vue-feather-icons';

    export default {
        name: 'VideoView',
        components: {
            LoopBar,
            PlusIcon,
            PauseIcon,
            RepeatIcon,
            TrashIcon,
            PlayIcon,
            RefreshCcwIcon,
            Loop,
            VueSlider,
            MarkStart,
            MarkEnd,
            CountIn,
            ClockIcon,
            Edit2Icon,
            CheckIcon,
            ExternalLinkIcon
        },
        data() {
            return {
                video: null,
                player: null,
                playheadStepAmount: null,
                secondsElasped: 0,
                loopsObj: {},
                activeLoopId: null,
                loopMode: false,
                countInMode: false,
                countInAmount: 3,
                tempo: 1,
                playheadDragging: false,
                playheadStartX: 0,
                playheadDiff: 0,
                playheadX: 0,
                previousPlayerState: null,
                editVideoMode: false,
                videoTitle: ''
            }
        },
        async mounted() {
            const { videoId } = this.$route.params;
            if (videoId) {
                const videoQuery = await db.videos.where('videoId').equals(videoId);
                let count = await videoQuery.count();
                if (count === 0) {
                    console.log('redirect to a 404');
                } else {
                    let video = await videoQuery.first();
                    this.video = video;
                    this.fetchLoops();
                    this.videoTitle = this.video.name;
                    // for now sets the active loop to be the first one in the loops array
                    setTimeout(() => {
                        const [firstLoop] = this.loops;
                        if (firstLoop) {
                            this.activeLoopId = firstLoop.id;
                        }
                    }, 500);
                }
            }

            if (this.$refs.player && this.$refs.wrapper) {
                this.player = new YTPlayer(this.$refs.player, {
                    width: this.$refs.wrapper.clientWidth,
                    height: this.$refs.wrapper.clientWidth/(16/9),
                    timeupdateFrequency: 1000/24
                });

                if (this.video) {
                    this.player.load(this.video.videoId);
                    this.player.on('timeupdate', this.handleTimeUpdate);
                    if (this.video.duration === 0) {
                        this.player.play();
                    }
                    this.playheadStepAmount = this.$refs.playheadContainer.clientWidth / this.video.duration;
                    let clipboard = new ClipboardJS(this.$refs.exportButton);
                    clipboard.on('success', () => {
                        this.$toasted.show('Link copied to clipboard, you can use this to share with anyone or any of your devices', {
                            position: 'top-center',
                            duration: 5000
                        })
                    })
                }

                this.videoResizeObserver = new ResizeObserver((entries) => {
                    const [wrapper] = entries;
                    this.player.setSize(wrapper.contentRect.width, wrapper.contentRect.width/(16/9));
                    this.playheadStepAmount = this.$refs.playheadContainer.clientWidth / this.video.duration;
                });
                this.videoResizeObserver.observe(this.$refs.wrapper);

            }


            // register shortcuts for being able to set the start and end of a loop
            document.addEventListener('keyup', this.handleGlobalKeyUp);

            // @TODO - next need to add suppport for touch
            document.addEventListener('mousedown', this.handleDragStart, false);
            document.addEventListener('mousemove', this.handleDrag, false);
            document.addEventListener('mouseup', this.handleDragEnd, false);
            document.addEventListener('touchstart', this.handleDragStart, false);
            document.addEventListener('touchmove', this.handleDrag, false);
            document.addEventListener('touchend', this.handleDragEnd, false);
        },
        beforeDestroy() {
            this.videoResizeObserver.disconnect();
            // register shortcuts for being able to set the start and end of a loop
            document.removeEventListener('keyup', this.handleGlobalKeyUp);

            document.removeEventListener('mousedown', this.handleDragStart, false);
            document.removeEventListener('mousemove', this.handleDrag, false);
            document.removeEventListener('mouseup', this.handleDragEnd, false);
            document.removeEventListener('touchstart', this.handleDragStart, false);
            document.removeEventListener('touchmove', this.handleDrag, false);
            document.removeEventListener('touchend', this.handleDragEnd, false);
        },
        computed: {
            loops() {
                return Object.values(this.loopsObj);
            },
            activeLoop() {
                if (this.activeLoopId) {
                    return this.loopsObj[this.activeLoopId];
                }
                return null;
            },
            playheadOffset() {
                if (this.playheadDragging) {
                    return this.playheadX;
                }
                if (this.playheadStepAmount) {
                    return this.playheadStepAmount * this.secondsElasped;
                }
                return 0;
            },
            secondsPretty() {
                let seconds = Math.floor(this.secondsElasped);
                return sf.convert(seconds).format('MM:SS');
            },
            durationPretty() {
                let seconds = Math.floor(this.video.duration);
                return sf.convert(seconds).format('MM:SS');
            },
            exportUrl() {
                let data = {
                    video: {
                        ...this.video,
                        name: this.videoTitle
                    },
                    loops: this.loops
                }
                let json = JSON.stringify(data);
                let crushed = JSONCrush(json);
                // let encoded = encodeURIComponent(json);
                let url = `https://loopsome.com/#/import?c=${crushed}`;
                return url;
            }
        },
        methods: {
            handleTimeUpdate(seconds) {
                if (!this.playheadDragging) {
                    this.secondsElasped = seconds;
                }

                // used to get a duration on video load incase the duration is never retrieved on intiial adding of the video
                if (this.video.duration === 0) {
                    let dur = this.player.getDuration();
                    if (dur > 0) {
                        this.video.duration = dur;
                        this.playheadStepAmount = this.$refs.playheadContainer.clientWidth / this.video.duration;
                        this.updateVideo();
                        this.player.stop();
                    }
                }

                if (this.loopMode) {
                    let current = parseFloat(seconds);
                    let end = parseFloat(this.activeLoop.end);
                    if (current >= end) {
                        this.seekToActiveLoopStart();
                        // this.player.seek(this.activeLoop.start);
                    }
                }
            },
            async handleAddLoop() {
                let newLoop = {
                    name: `New Loop ${this.loops.length + 1}`,
                    start: 0,
                    end: 0
                }
                let newLoopId = await db.loops.put({
                    ...newLoop,
                    videoId: this.video.id
                })
                this.activeLoopId = newLoopId;
                this.loopMode = false;
                this.fetchLoops();
            },
            async fetchLoops() {
                let loops = await db.loops.where('videoId').equals(this.video.id).toArray();
                const _loops = {}
                loops.forEach(loop => _loops[loop.id] = loop);
                this.loopsObj = _loops
            },
            async updateActiveLoop() {
                if (this.activeLoopId) {
                    await db.loops.update(this.activeLoopId, this.activeLoop);
                }
            },
            async updateLoop(loopId) {
                let loop = this.loopsObj[loopId];
                if (loop) {
                    await db.loops.update(loopId, loop);
                }
            },
            async updateVideo() {
                await db.videos.update(this.video.id, this.video);
                this.$emit('fetch-videos');
            },
            handleUpdateLoopName({ loopId, name }) {
                let loop = this.loopsObj[loopId];
                if (loop) {
                    loop.name = name;
                    this.updateLoop(loopId);
                }
            },
            async handleDeleteLoop({loopId}) {
                try {
                    await db.loops.delete(loopId);
                    Vue.delete(this.loopsObj, loopId);
                    if (this.activeLoopId === loopId) {
                        this.activeLoopId = null;
                    }
                } catch (err) {
                    console.log(err);
                    console.log('invalid loop id');
                }
            },
            setActiveLoopStart() {
                this.activeLoop.start = this.secondsElasped;
                if (this.activeLoop.start > this.activeLoop.end) {
                    this.activeLoop.end = 0;
                }
                this.updateActiveLoop();
            },
            setActiveLoopEnd() {
                 this.activeLoop.end = this.secondsElasped;
                this.updateActiveLoop();
            },
            seekToActiveLoopStart() {
                let start = this.activeLoop.start;
                if (this.countInMode) {
                    start -= this.countInAmount;
                }
                this.player.seek(start);
                this.secondsElasped = start;
            },
            handleLoopClick(loopId) {
                this.activeLoopId = loopId;
                this.seekToActiveLoopStart();
            },
            handleGlobalKeyUp(e) {
                const { key, keyCode, target } = e;
                // if it's not coming from the body and is an input or something then we don't want to run this
                if (target.localName === 'input') {
                    return;
                }
                if (this.activeLoop) {
                    if (key === 's') {
                        // mark the start for the loop
                        this.setActiveLoopStart();
                    }
                    if (key === 'e') {
                        // mark the end of the active loop
                        this.setActiveLoopEnd();
                    }
                    if (key === 'r') {
                        this.seekToActiveLoopStart();
                    }
                    if (key === 'l') {
                        this.loopMode = !this.loopMode;
                    }
                    if (key === 'c') {
                        this.countInMode = !this.countInMode;
                    }
                    if (key === 'm') {
                        if (this.player.isMuted()) {
                            this.player.unMute();
                        } else {
                            this.player.mute();
                        }
                    }
                    if (key === 'ArrowRight') {
                        this.secondsElasped += 5;
                        this.player.seek(this.secondsElasped);
                    }
                    if (key === 'ArrowLeft') {
                        this.secondsElasped -= 5;
                        this.player.seek(this.secondsElasped);
                    }
                    if (key === '.') {
                        this.secondsElasped += 1;
                        this.player.seek(this.secondsElasped);
                    }
                    if (key === ',') {
                        this.secondsElasped -= 1;
                        this.player.seek(this.secondsElasped);
                    }
                    // lets handle the tempo on arrow down and up
                    if (key === "ArrowUp") {
                        if (this.tempo < 1) {
                            this.tempo += 0.1
                        }
                    }
                    if (key === "ArrowDown") {
                        if (this.tempo > 0.4) {
                            this.tempo -= 0.1
                        }
                    }
                }
                if (keyCode === 32 || key === 'k') {
                    let state = this.player.getState();
                    if (state === 'paused' || state === 'ended' || state === 'unstarted' || state === 'cued') {
                        this.player.play();
                    } else {
                        this.player.pause();
                    }
                }
            },
            handleDeleteVideo() {
                let loopIds = this.loops.map(loop => loop.id);
                try {
                    db.transaction('rw', db.videos, db.loops, async () => {
                        await db.loops.bulkDelete(loopIds);
                        await db.videos.delete(this.video.id);
                        this.$emit('video-deleted');
                        this.$router.push('/');
                    })
                } catch (err) {
                    if (err) {
                        console.log(err);
                        console.log('failed to delete video');
                    }
                }
            },
            handleDragStart(e) {
                if (e.target === this.$refs.playheadMarker || e.target === this.$refs.playheadMarkerLabel) {
                    this.previousPlayerState = this.player.getState();
                    // turn off loop mode as it makes things difficult
                    this.loopMode = false;

                    if (this.previousPlayerState === 'cued' || this.previousPlayerState === 'unstarted') {
                        this.player.play();
                    } else {
                        this.player.pause();
                    }
                    this.playheadDragging = true;
                    // use the start of the wrapping container of the playhead
                    this.playheadStartX = this.$refs.playheadContainer.offsetLeft;
                    if (e.type === 'touchstart') {
                        let [touch] = e.touches;
                        this.playheadX = (touch.pageX - this.playheadStartX);
                    } else {
                        this.playheadX = (e.pageX - this.playheadStartX);
                    }
                }
            },
            handleDrag(e) {
                if (this.playheadDragging) {
                    let min = 0;
                    let max = this.$refs.playheadContainer.clientWidth;
                    let diff = 0;
                    if (e.type === 'touchmove') {
                        let [touch] = e.touches;
                        diff = touch.pageX - this.playheadStartX;
                    } else {
                        diff = e.pageX - this.playheadStartX;
                    }
                    if (diff >= min && diff <= max) {
                        this.playheadX = diff;
                        // playheadX divided by step amount is seconds elasped
                        let diffInSeconds = this.playheadX/this.playheadStepAmount;
                        this.secondsElasped = diffInSeconds;
                    }
                }
            },
            handleDragEnd(e) {
                this.playheadDragging = false;
                if (e.target === this.$refs.playheadMarker || e.target === this.$refs.playheadMarkerLabel) {
                    this.player.seek(this.secondsElasped);
                    if (this.previousPlayerState === 'playing') {
                        this.player.play();
                    } else {
                        this.player.pause();
                    }
                }
            },
            handleTogglePlay() {
                let stoppedStates = ['paused', 'unstarted', 'cued', 'ended'];
                if (stoppedStates.indexOf(this.player.getState()) !== -1) {
                    this.player.play();
                } else {
                    this.player.pause();
                }
            },
            handleTrackClick(e) {
                let startX = this.$refs.playheadContainer.offsetLeft;
                let diff = 0;
                if (e.type === 'touchend') {
                    let [touch] = e.changedTouches;
                    diff = (touch.pageX - startX);
                } else {
                    diff = (e.pageX - startX);
                }
                let diffInSeconds = diff/this.playheadStepAmount;
                this.secondsElasped = diffInSeconds;
                this.player.seek(this.secondsElasped);
            },
            async handleVideoTitleChange() {
                this.editVideoMode = false;
                await db.videos.update(this.video.id, {
                    name: this.videoTitle
                })
            }
        },
        watch: {
            tempo(newVal) {
                this.player.setPlaybackRate(newVal);
            },
        }
    }
</script>

<style lang="css">
    .timeline {
        /* background-image: repeating-linear-gradient(to right,
        transparent,
        transparent 6px,
        black 6px,
        black 8px); */
        background-image: repeating-linear-gradient(to right,
        rgba(0,0,0,0.3),
        rgba(0,0,0,0.3) 2px,
        transparent 2px,
        transparent calc(var(--step-amount) * 2));
        border-bottom: 2px solid rgba(0,0,0,0.3);
    }
    .timeline-marker {
        /* background-image: repeating-linear-gradient(to right,
        transparent,
        transparent 6px,
        black 6px,
        black 8px); */
        background-image: repeating-linear-gradient(to right,
        rgba(0,0,0,0.5),
        rgba(0,0,0,0.5) 2px,
        transparent 12px,
        transparent 24px);
    }
    .playhead-marker {
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        @apply bg-red-600;
        user-select: none;
    }
    .playhead-label {
        transform: translateX(-50%);
    }

    .control-button {
        border: 1px solid rgba(0,0,0,0.2);
        border-bottom-width: 2px;
        transition: all 0.2s ease-in-out;
    }

    .control-button:hover {
        top: -1px;
        border-color: rgba(0,0,0,0.3);
    }

    .control-button:active {
        top: 3px;
    }

    .control-button.isActive {
        border-color: var(--primary);
    }

    .vue-slider-process {
        background-color: var(--primary);
    }
</style>